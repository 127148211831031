import React from 'react'
import { languageHandler } from '../../../App'
import styles from "./Atelier.module.scss"

export default function Atelier() {

  const atelierDescription = () => 
    <div>
      <div className={styles.atelierTitle}>
        {languageHandler.getString("atelier")}
      </div>
      
      <div className={styles.descriptionSection}>
        {languageHandler.getString("atelierDescriptionPartOne")}
      </div>
      
      <div className={styles.descriptionSection}>
        {languageHandler.getString("atelierDescriptionPartTwo")}
      </div>

      <div className={styles.descriptionSection}>
        {languageHandler.getString("atelierDescriptionPartThree")}
      </div>

      <div className={styles.descriptionSection}>
        {languageHandler.getString("atelierDescriptionPartFour")}
      </div>
    </div>

  const offerDescription = () => 
    <div>
      <div className={styles.offerTitle}>
        {languageHandler.getString("offer")}
      </div>
      
      <div className={styles.offerDescriptionTitle}>
        {languageHandler.getString("offerSubTitlePartOne")}
      </div>
      
      <div className={styles.descriptionSection}>
        {languageHandler.getString("offerDescriptionPartOne")}
      </div>

      <div className={styles.offerDescriptionTitle}>
        {languageHandler.getString("offerSubTitlePartTwo")}
      </div>

      <div className={styles.descriptionSection}>
        {languageHandler.getString("offerDescriptionPartTwo")}
      </div>

      <div className={styles.offerDescriptionTitle}>
        {languageHandler.getString("offerSubTitlePartThree")}
      </div>

      <div className={styles.descriptionSection}>
        {languageHandler.getString("offerDescriptionPartThree")}
      </div>

      <div className={styles.offerDescriptionTitle}>
        {languageHandler.getString("offerSubTitlePartFour")}
      </div>

      <div className={styles.descriptionSection}>
        {languageHandler.getString("offerDescriptionPartFour")}
      </div>
    </div>

  const leadingArchitect = () =>
    <div>
      <div className={styles.leadingArchitect}>
        {languageHandler.getString("leadingArchitect")}
      </div>

      <div className={styles.leadingArchitectProfessionalTitle}>
        MGR INŻ. ARCH.
      </div>
      ADRIAN WŁODARCZEWSKI
      <div>
        {languageHandler.getString("interiorDesigner")}
        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        {languageHandler.getString("architect")}
        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        {languageHandler.getString("graphicDesigner")}
      </div>
    </div>

  const education = () =>
    <div>
      <div className={styles.education}>
        {languageHandler.getString("education")}
      </div>
      <div className={"row no-gutters"}>
        <div className={"col-auto"}>
          2012 - 2016
        </div>
        <div className={"col-auto"}>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        </div>
        <div className={"col"}>
          {languageHandler.getString("educationPartOne")}
        </div>
      </div>

      <div className={"row no-gutters"}>
        <div className={"col-auto"}>
          2015 - 2015
        </div>
        <div className={"col-auto"}>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        </div>
        <div className={"col"}>
          {languageHandler.getString("educationPartTwo")}
        </div>
      </div>

      <div className={"row no-gutters"}>
        <div className={"col-auto"}>
          2016 - 2019
        </div>
        <div className={"col-auto"}>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        </div>
        <div className={"col"}>
          {languageHandler.getString("educationPartThree")}
        </div>
      </div>
    </div>

  const achievements = () =>
    <div>
      <div className={styles.achievements}>
        {languageHandler.getString("achievements")}
      </div>

      <div className={"row no-gutters"}>
        <div className={"col-auto"}>
          {languageHandler.getString("thirdPlace")}
        </div>
        <div className={"col-auto"}>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        </div>
        <div className={"col"}>
          {languageHandler.getString("achievementPartOne")}
        </div>
      </div>
    </div>

  const realization = (year: string, placeId: string, countryId: string, infoId: string) =>
    <div className={"row no-gutters"}>
      <div className={"col-4 col-sm-3"}>
        {year} {languageHandler.getString(placeId)}, {languageHandler.getString(countryId)}
      </div>
      <div className={"col-auto"}>
        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
      </div>
      <div className={"col"}>
        {languageHandler.getString(infoId)}
      </div>
    </div>

  const realizations = () =>
    <div>
      <div className={styles.realizations}>
        {languageHandler.getString("realizations")}
      </div>

      {realization("2021", "lodz", "poland", "realizationAloneSixteen")}
      {realization("2021", "lodz", "poland", "realizationAloneFifteen")}
      
      {realization("2020", "warsaw", "poland", "realizationAloneFourteen")}
      {realization("2020", "ksawerow", "poland", "realizationAloneThirteen")}
      {realization("2020", "lodz", "poland", "realizationAloneTwelve")}
      {realization("2020", "dobron", "poland", "realizationAloneEleven")}
      {realization("2020", "piatkowisko", "poland", "realizationAloneTen")}
      {realization("2020", "lodz", "poland", "realizationAloneNine")}

      {realization("2019", "warsaw", "poland", "realizationAloneEight")}
      {realization("2019", "lodz", "poland", "realizationAloneSeven")}
      {realization("2019", "warsaw", "poland", "realizationAloneSix")}
      {realization("2019", "lodz", "poland", "realizationAloneFive")}

      {realization("2018", "lodz", "poland", "realizationAloneFour")}
      {realization("2018", "dublin", "ireland", "realizationAloneThree")}

      {realization("2017", "wroclaw", "poland", "realizationAloneTwo")}

      {realization("2016", "dobron", "poland", "realizationAloneOne")}

      <div className={styles.inCoopWith}>
        {languageHandler.getString("inCooperationWithMswPiotrMarcinkowski")}
      </div>
      
      {realization("2020", "lodz", "poland", "realizationInCoopWithMswPiotrMarcinkowskiTen")}
      {realization("2020", "radomsko", "poland", "realizationInCoopWithMswPiotrMarcinkowskiNine")}
      {realization("2020", "lodz", "poland", "realizationInCoopWithMswPiotrMarcinkowskiEight")}

      {realization("2019", "lodz", "poland", "realizationInCoopWithMswPiotrMarcinkowskiSeven")}
      {realization("2019", "slupsk", "poland", "realizationInCoopWithMswPiotrMarcinkowskiSix")}

      {realization("2018", "lodz", "poland", "realizationInCoopWithMswPiotrMarcinkowskiFive")}
      {realization("2018", "lodz", "poland", "realizationInCoopWithMswPiotrMarcinkowskiFour")}

      {realization("2017", "lodz", "poland", "realizationInCoopWithMswPiotrMarcinkowskiThree")}
      {realization("2017", "lodz", "poland", "realizationInCoopWithMswPiotrMarcinkowskiTwo")}
      {realization("2017", "lodz", "poland", "realizationInCoopWithMswPiotrMarcinkowskiOne")}

      <div className={styles.inCoopWith}>
        {languageHandler.getString("inCooperationWithBonarteAgnieszkaPierzchala")}
      </div>
      
      {realization("2021", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyEight")}

      {realization("2020", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaTwentySeven")}
      {realization("2020", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaTwentySix")}
      {realization("2020", "lask", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyFive")}

      {realization("2019", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyFour")}
      {realization("2019", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyThree")}
      {realization("2019", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyTwo")}
      {realization("2019", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyOne")}
      {realization("2019", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaTwenty")}

      {realization("2018", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaNineteen")}
      {realization("2018", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaEighteen")}
      {realization("2018", "dobron", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaSeventeen")}
      {realization("2018", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaSixteen")}
      {realization("2018", "warsaw", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaFifteen")}
      {realization("2018", "warsaw", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaFourteen")}

      {realization("2017", "warsaw", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaThirteen")}
      {realization("2017", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaTwelve")}
      {realization("2017", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaEleven")}
      {realization("2017", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaTen")}
      {realization("2017", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaNine")}
      {realization("2017", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaEight")}
      {realization("2017", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaSeven")}

      {realization("2016", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaSix")}
      {realization("2016", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaFive")}
      {realization("2016", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaFour")}
      {realization("2016", "lodz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaThree")}
      {realization("2016", "wiaczynDolny", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaTwo")}
      {realization("2016", "zgierz", "poland", "realizationInCoopWithBonarteAgnieszkaPierzchalaOne")}
    </div>

  return (
    <div id="atelier" className={styles.atelierGrid}>

      <div className={styles.leftSide}>
        {atelierDescription()}
        {offerDescription()}
      </div>

      <div className={styles.rightSide}>
        {leadingArchitect()}
        {education()}
        {achievements()}
        {realizations()}
      </div>

    </div>
  )
}
