import { getProjectData } from "../../../../helpers/CommonFuntions";
import { CompletedInvestmentData } from "../completedInvestment/CompletedInvestment";

const SHORT_DESCRIPTION = "interiorDesignProjectShortDescription"
const LONG_DESCRIPTION = "interiorDesignProjectLongDescription"
const BASE_PATH = "/assets/img/interiorDesign/project_"

export const interiorDesignData: CompletedInvestmentData[]  = [
  getProjectData("26", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",
    "12", "13", "14", "15", "16", "17", "18", "19"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("19", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",
    "12", "13", "14", "15", "16", "17", "18", "19", "20"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("22", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",
    "12", "13", "14", "15"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("11", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",
    "12", "13", "14", "15", "16", "17", "18"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("25", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",
    "12"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("23", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("24", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("20", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("18", [
    "0", "1", "2", "3", "4", "5", "6", "7"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("27", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("15", [
    "0", "1", "2", "3", "4",
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("28", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",
    "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22",
    "23", "24", "25", "26", "27", "28", "29"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("1", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("2", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("17", [
    "0", "1", "2", "3", "4", "5", "6"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("3", [
    "0", "1", "2", "3", "4", "5", "6"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("21", [
    "0", "1", "2", "3", "4", "5", "6", "7"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("12", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",
    "12", "13", "14", "15", "16", "17", "18", "19", "20"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("10", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

  getProjectData("16", [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",
    "12", "13"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),

]
