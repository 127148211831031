
import React, { Component } from 'react'
import CompletedInvestment from '../completedInvestment/CompletedInvestment'
import SeeMoreButton from '../seeMoreButton/SeeMoreButton'
import { interiorDesignData } from './InteriorDesignData'
import styles from "./InteriorDesign.module.scss"
import { languageHandler } from '../../../App'

type InteriorDesignProps = {}
type InteriorDesignState = {
  showMore: boolean
}
export default class InteriorDesign extends Component<InteriorDesignProps, InteriorDesignState> {

  constructor(props: InteriorDesignProps) {
    super(props)

    this.state = {
      showMore: false
    }
  }

  seeMoreButtonOnClick = () => {
    this.setState({showMore: !this.state.showMore})
  }

  render() {
    const interiorDesignNumberOfItems = this.state.showMore ? interiorDesignData.length : 5

    return (
      <div id="interiordesign" className={styles.interiorDesign}>
        <div className={styles.interiorDesignContent}>
          <div className={styles.title}>
            {languageHandler.getString("interiorDesign")} - {languageHandler.getString("portfolioOfCompletedInvestments")} 
          </div>

          <ul>
            {interiorDesignData.slice(0, interiorDesignNumberOfItems).map((investment) => (
              <li className={styles.completedInvestmentItem} key={Math.random()}>
                <CompletedInvestment completedInvestmentData = {investment}/>
              </li>
            ))}

          </ul>

          <div className={styles.seeMoreButtonContainer}>
            <SeeMoreButton 
              onClick={this.seeMoreButtonOnClick}
              showSeeMore={!this.state.showMore}/>
          </div>
        </div>
      </div>
    )
  }
}
