import React from 'react'
import Header from './header/Header';
import Main from './main/Main';
import Footer from './footer/Footer';
import LanguageHandler, { Translations } from '../helpers/LanguageHandler';
import polishLanguage from '../helpers/languages/pl';

const translations: Translations = {
  "pl": polishLanguage
}

export const languageHandler = new LanguageHandler(translations)
languageHandler.init("pl")

export default function App() {
  return (
    <div>
      <Header/>
      <Main/>
      <Footer/>
    </div>
  )
}
