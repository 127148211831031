
import React, { Component } from 'react'
import CompletedInvestment from '../completedInvestment/CompletedInvestment'
import { contemporaryArchitectureData } from './ContemporaryArchitectureData'
import styles from "./ContemporaryArchitecture.module.scss"
import { languageHandler } from '../../../App'

type ContemporaryArchitectureProps = {}
type ContemporaryArchitectureState = {
  showMore: boolean
}
export default class ContemporaryArchitecture 
  extends Component<ContemporaryArchitectureProps, ContemporaryArchitectureState> {

  constructor(props: ContemporaryArchitectureProps) {
    super(props)

    this.state = {
      showMore: false
    }
  }

  render() {
    return (
      <div id="contemporaryarchitecture" className={styles.contemporaryArchitecture}>
        <div className={styles.contemporaryArchitectureContent}>
          <div className={styles.title}>
            {languageHandler.getString("contemporaryArchitecture")} - {languageHandler.getString("portfolioOfCompletedInvestments")} 
          </div>

          {contemporaryArchitectureData.map(investment => (
              <div className={styles.completedInvestmentItem} key={Math.random()}>
                <CompletedInvestment completedInvestmentData = {investment}/>
              </div>
          ))}

        </div>
      </div>
    )
  }
}
