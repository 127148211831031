import React from 'react'
import styles from "./WhatDoWeDo.module.scss"
import arrow  from "../../../../img/arrow.png"
import contemporaryArchitecture  from "../../../../img/contemporaryArchitecture.png"
import interiorDesign  from "../../../../img/interiorDesign.png"
import { languageHandler } from '../../../App'

export default function WhatDoWeDo() {
  const interiorDesignPartOne = () => 
    <div>
      <div>
        {languageHandler.getString("wholeProjectIncludingInventorying")}
      </div>
      <div>
        |{languageHandler.getString("conceptualDesign")}|
      </div>
      <div>
        |{languageHandler.getString("executiveProject")}|
      </div>
      <div>
        |{languageHandler.getString("authorSupervision")}|
      </div>
      <div>
        {languageHandler.getString("optionalWholeInvestmentSupervision")}
      </div>
    </div>

  const interiorDesignPartTwo = () => 
    <div>
      <div>
        |{languageHandler.getString("architecturalInventorying")}|
      </div>
      <div>
        |{languageHandler.getString("designsOfFunctionalSystems")}|
      </div>
      <div>
        |{languageHandler.getString("designsOfSanitaryAndElectricalInstallations")}|
      </div>
    </div>
    
  const contemporaryArchitecturePartOne = () => 
    <div>
      {languageHandler.getString("wholeIndividualFamilyHouseProjects")}
    </div>

  const contemporaryArchitecturePartTwo = () => 
    <div>
      {languageHandler.getString("conceptualIndivifualBigFamilyHouseProjects")}
    </div>

  return (
    <div id="whatDoWeDo" className={styles.whatDoWeDoGrid}>
      <div className={styles.interiorDesignPartOne}>
        <div className={styles.cellContainer}>
          {interiorDesignPartOne()}
        </div>
      </div>
      
      <div className={styles.interiorDesignImage}>
        <div className={styles.cellContainer}>
          <img src={interiorDesign} className={styles.infoImage} alt="Interior design"/>
        </div>
      </div>

      <div className={styles.interiorDesignPartTwo}>
        <div className={styles.cellContainer}>
          {interiorDesignPartTwo()}
        </div>
      </div>

      <div className={styles.leftArrow}>
        <div className={styles.cellContainer}>
          <img src={arrow} className={styles.leftArrowImg} alt="Left arrow"/>
        </div>
      </div>

      <div className={styles.whatDoWeDo}>
        <div className={styles.cellContainer}>
          {languageHandler.getString("whatDoWeDo")}
        </div>
      </div>

      <div className={styles.rightArrow}>
        <div className={styles.cellContainer}>
          <img src={arrow} className={styles.rightArrowImg} alt="Right arrow"/>
        </div>
      </div>

      <div className={styles.contemporaryArchitecturePartOne}>
        <div className={styles.cellContainer}>
          {contemporaryArchitecturePartOne()}
        </div>
      </div>

      <div className={styles.contemporaryArchitectureImage}>
        <div className={styles.cellContainer}>
          <img src={contemporaryArchitecture} className={styles.infoImage} alt="Contemporary architecture"/>
        </div>
      </div>

      <div className={styles.contemporaryArchitecturePartTwo}>
        <div className={styles.cellContainer}>
          {contemporaryArchitecturePartTwo()}
        </div>
      </div>
    </div>
  )
}
