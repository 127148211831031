import React from 'react'
import styles from './Footer.module.scss';

export default function Footer() {
  return (
    <div className={styles.footer}>
      ATELIER WŁODARCZEWSKI | UL. WILEŃSKA | 95-200 PABIANICE | NIP 7312065527 | REGON 383803972 | © 2023 ATELIER ADRIAN WŁODARCZEWSKI. ALL RIGHTS RESERVED.
    </div>
  )
}
