export type Translation = {
  [namespace: string]: string | Translation
}

export type Translations = {
  [locale: string]: Translation
}

export default class LanguageHandler {
  private translations: Translations
  private locale: string | undefined
  constructor(translations: Translations) {
    this.translations = translations
  }

  init = (locale: string) => {
    this.locale = locale
  }

  getString = (key: string): string | undefined => {
    if (!this.locale) throw new Error("Call languageHandler.init(locale) and setup chosen language")

    const translation = this.translations[this.locale]
    const keySplit = key.split(".")
    if (keySplit.length <= 1) return translation[key] as string | undefined

    let translationValue = translation as any
    keySplit.forEach(key => {
      translationValue = translationValue[key]
    })

    return translationValue as unknown as string | undefined
  }
}
