import { Translation } from "../LanguageHandler"

const polishLanguage: Translation = {
  atelier: "Atelier",
  interiorDesign: "Architektura Wnętrz",
  contemporaryArchitecture: "Architektura Współczesna",
  contact: "Kontakt",

  whatDoWeDo: "Czym się zajmujemy?",
  wholeProjectIncludingInventorying: "Kompletne projekty architektury wnętrz zawierające inwentaryzację",
  conceptualDesign: "Projekt koncepcyjny",
  executiveProject: "Projekt wykonawczy",
  authorSupervision: "Nadzór autorski",
  optionalWholeInvestmentSupervision: "Opcjonalnie całkowitą obsługę inwestycji do momentu przekazania kluczy",

  architecturalInventorying: "Inwentaryzacje architektoniczne",
  designsOfFunctionalSystems: "Projekty układów funkcjonalnych",
  designsOfSanitaryAndElectricalInstallations: "Projekty instalacji sanitarnych i elektrycznych",

  wholeIndividualFamilyHouseProjects: "Kompletne projekty indywidualne domów jednorodzinnych wraz z procesem uzyskania decyzji o pozwoleniu na budowę ",
  conceptualIndivifualBigFamilyHouseProjects: "Koncepcyjne projekty indywidualne domów jednorodzinnych i inne o skali nieprzekraczającej 400m2 powierzchni użytkowej",

  portfolioOfCompletedInvestments: "Portfolio zrealizowanych inwestycji",

  seeMore: "Zobacz więcej",
  seeLess: "Zobacz mniej",
  
  previousState: "Stan pierwotny",
  realization: "Realizacja",

  interiorDesignProjectShortDescription1: "DOM JEDNORODZINNY 130M2 | PIĄTKOWISKO",
  interiorDesignProjectLongDescription1: "Wnętrze strefy dziennej zostało zaprojektowane na etapie projektu budowlanego. Uwzględniliśmy wtedy istotne osie oraz otwarcia na otoczenie, komponując nierozłączne zespolenie domu z naturą. Główną dekoracją jadalni jest widoczny na zewnątrz klon japoński, tworzący naturalną kurtynę od strony ulicy nie ograniczając przy tym zachodniego słońca dla całej strefy. Aranżację uzupełniają surowe, czyste, skandynawskie materiały, w tym m.in. wewnętrzne okiennice, w ciągu dnia pełniące rolę ramy obrazu, który stanowi ogrodowa zieleń.",

  interiorDesignProjectShortDescription2: "APARTAMENT 64M2 | APARTAMENTY ILUMINO | ŁÓDŹ",
  interiorDesignProjectLongDescription2: "Wyjątkowo nowocześnie, niezwykle elegancko, niebywale estetycznie. Spójne wnętrze zdominowane ciepłym fornirem, nieskazitelną bielą oraz zawsze klasyczną czernią. Gabinet - prawidłowo doświetlone miejsce pracy uzupełnione czarnym wykończeniem ścian. Prosta, jasna sypialnia, dopełniona pionowymi lamelami ściennymi. Dla kontrastu oraz możliwości wnętrza - dwie wersje łazienki.",

  interiorDesignProjectShortDescription3: "APARTAMENT 88M2 | LEVITYN APARTAMENTY | PABIANICE",
  interiorDesignProjectLongDescription3: "Pracownia Atelier Włodarczewski jako Oficjalny Architekt Wnętrz inwestycji Levityn Apartamenty prezentuje projekt koncepcyjny jednej z możliwości aranżacji wnętrza w kompleksie. Apartament nawiązuje do atutów nowego osiedla takich jak ekologiczne rozwiązania, bliskość natury, niecodzienna architektura i wiele innych!",

  interiorDesignProjectShortDescription10: "APARTAMENT 87M2 | APARTAMENTY KRAKOWSKA | ŁÓDŹ",
  interiorDesignProjectLongDescription10: "Pastelowe wnętrze z dominującym motywem lastryko uzupełnione wspaniale komponującym się laminatem imitującym drewno w nowej inswestycji Apartamenty Krakowska",

  interiorDesignProjectShortDescription11: "DOM JEDNORODZINNY 280M2 | KONSTANTYNÓW ŁÓDZKI",
  interiorDesignProjectLongDescription11: "Opis powyższego projektu to kilkutomowa powieść, z tego powodu musimy ją skrócić do lakonicznych kilku słów. Aranżacja wnętrza domu jednorodzinnego w kolorach ziemi z ukrytym aneksem kuchennym, strefą dzienną z podziałem na miejsce relaksu oraz przestrzeń tv oraz przeszkloną łazienką w sypialni głównej wykończoną zgodnie z charakterem całego parteru. Zdecydowanie jeden z naszych ulubionych projektów",

  interiorDesignProjectShortDescription12: "DOM JEDNORODZINNY 260M2 | KSAWERÓW",
  interiorDesignProjectLongDescription12: "Rewolucja w 10-letnim domu jednorodzinnym w Ksawerowie. Główne oczekiwania Inwestorów - powiększony, przestronny aneks kuchenny, rearanżacja funkcji strefy dziennej oraz jasna, otwarta lecz ciepła w odbiorze przestrzeń wspólna. Na piętrze modyfikacji uległy pokoje dzieci. Jasne, skandynawskie wnętrze z akcentem stylu boho było marzeniem nastoletniej fanki koszykówki i roślin. Absolutnym przeciwieństwem były oczekiwania brata, który upodobał sobie styl loftowy z industrialnymi dodatkami. Miłośnik gekonów, Marvel'a oraz Harry'ego Potter'a był zdecydowanie usatysfakcjonowany doborem dekoracji.",

  interiorDesignProjectShortDescription15: "STREFA WSPÓLNA | LEVITYN APARTAMENTY | PABIANICE",
  interiorDesignProjectLongDescription15: "Projekt architektury wnętrza strefy wspólnej - komunikacji pionowej oraz poziomej w zespole budynków mieszkalnych, wielorodzinnych w Pabianicach. Głównym założeniem projektu było doprowadzenie wnętrza do spójności z formą oraz stylem architektury budynków. Zwróciliśmy szczególną uwagę na strefę wejściową, która jest zarówno wizytówka inwestycji jak i elementem witającym mieszkańców oraz potencjalnych gości",

  interiorDesignProjectShortDescription16: "APARTAMENT 86M2 | LEVITYN APARTAMENTY | PABIANICE",
  interiorDesignProjectLongDescriptio16: "Tym razem para Inwestorów postawiła na odważne, surowe wnętrze w minimalistycznej odsłonie z dużym naciskiem na brak niepożądanych dekoracji i sztucznego bałaganu",

  interiorDesignProjectShortDescription17: "APARTAMENT 46M2 | LEVITYN APARTAMENTY | PABIANICE",
  interiorDesignProjectLongDescription17: "Biel, czerń, drewno - rozpowszechnione zestawienie niezwykle często pojawiające się w nowoczesnych wnętrzach, jednak w tym przypadku, tak dalece odbiegające od pospolitych aranżacji. Typowy styl, dzięki odpowiednim proporcjom, rodzajowi i odcieniu drewna, dodatku wystawnego lastryko oraz stonowanym elementom wyposażenia, został przeobrażony w elegancką, jasną przestrzeń z ukrytą łazienką i wygodną, pokaźną sypialnią",

  interiorDesignProjectShortDescription18: "APARTAMENT 44M2 | NOWY NIEBRÓW | TOMASZÓW MAZOWIECKI",
  interiorDesignProjectLongDescription18: "Z przyjemnością oznajmujemy - pracownia Atelier Włodarczewski będzie miałaprzyjemność sprawowania opieki nad architekturą wnętrz w kolejnej inwestycji, tym razem w Tomaszowie Mazowieckim. Prezentujemy więc projekt koncepcyjny jednej z wielu możliwości aranżacji. Dziękujemy za zaufanie deweloperowi i wierzymy, że współpraca będzie nadzwyczajnie owocna!",

  interiorDesignProjectShortDescription19: "DOM JEDNORODZINNY 289M2 | GÓRKA PABIANICKA",
  interiorDesignProjectLongDescription19: "Nie ma większej przyjemności niż praca nad wnętrzem budynku zaprojektowanego indywidualnie, pod potrzeby Klientów. Poniżej znajdziecie najlepszy tego przykład! A skoro o Klientach mowa - Niezwykle sympatyczne małżeństwo ceniące ponadczasową klasykę we współczesnym wydaniu z zamiłowaniem do sztuki, otwarte na niestandardowe rozwiązania. Ponadto od samego początku projektu obdarzyli nas pełnym zaufaniem w kwestiach aranżacyjnych opisując swoje instrukcje wyłącznie powyższymi słowami.",

  interiorDesignProjectShortDescription20: "APARTAMENT 46M2 | PONIATOWSKI PARK | ŁÓDŹ",
  interiorDesignProjectLongDescription20: "Nowoczesny, lecz nieoczywisty styl. Standardowe barwy w niestandardowym wydaniu. Akcent klasycznej czerni dla Inwestora otwartego na odważne rozwiązania. Podsumowując - to co kochamy najbardziej.",

  interiorDesignProjectShortDescription21: "DOM JEDNORODZINNY 133M2 | RYDZYNY",
  interiorDesignProjectLongDescription21: "Współczesna aranżacja z elementami nieprzemijalnej klasyki, kolorystycznym akcentem oraz uwydatnionym naciskiem na przestronność, przyjazność barw, klarowną czystość oraz jak zawsze - funkcjonalność.",

  interiorDesignProjectShortDescription22: "DOM JEDNORODZINNY 337M2 | TUSZYN",
  interiorDesignProjectLongDescription22: "Poniższy projekt absolutnie możemy nazwać jednym z naszych architektonicznych \"dzieci\", z przyjemnością będziemy towarzyszyć mu w procesie realizacji i dojrzewania! Rozległe pole do popisu dała nam indywidualnie zaprojektowana bryła budynku, która swoją charakterystyką zadbała o prawidłowy układ funkcjonalno-użytkowy oraz przenikanie się wnętrza z otaczającym środowiskiem. Sukcesywnie - rodzina Inwestorów jednoznacznie wskazała najistotniejsze dla nich elementy wnętrza - nieszablonowe rozwiązania, nowatorskie wykończenie i niepowtarzalny odbiór, niespotykany w standardowych domach.",

  interiorDesignProjectShortDescription23: "APARTAMENT 55M2 | NAWROT34 | ŁÓDŹ",
  interiorDesignProjectLongDescription23: "Coś zupełnie innego, absolutnie wyjątkowego, niespotykanego w naszym portfolio. Projekt architektury wnętrz lokalu mieszkalnego o powierzchni 55 mkw. W inwestycji Nawrot 34 w łódzkim Śródmieściu w stylistyce ewidentnie niesprecyzowanej. Idea wyjściowa zakładała nawiązanie do połączenia konwencji american farmhouse, modern village i modern classic, po dodaniu kamienicznego charakteru, dekoracyjnego sznytu, ponad wszystko funkcjonalnych elementów loftowych i współczesnych uzupełnień przestrzennych uzyskaliśmy - co najważniejsze - aprobatę i uznanie Inwestorów. O optycznych i zręcznych sztuczkach zastosowanych we wnętrzu mógłby powstać pełny artykuł, jednak pozostawimy to na etap realizacji.",

  interiorDesignProjectShortDescription24: "APARTAMENT 75M2 | LEVITYN APARTAMENTY | PABIANICE",
  interiorDesignProjectLongDescription24: "Kolejna aranżacja wnętrza w inwestycji Levityn Apartamenty! Uroczyście rozpoczynamy już zapewne trzecią dziesiątkę projektów przy ulicy Popławskiej w Pabianicach. Tym razem - apartament inwestycyjny, uniwersalny, rodzinny, z miejscem do pracy oraz dodatkowym pomieszczeniem do zagospodarowania, wyodrębnioną, przestronną garderobą i pokaźną strefą dzienną.",

  interiorDesignProjectShortDescription25: "DOM JEDNORODZINNY 198M2 | PABIANICE",
  interiorDesignProjectLongDescription25: "Styl, który zdecydowanie odpowiada naszej konwencji, dlatego tak bardzo jesteśmy zadowoleni z efektu końcowego. Jasna przestrzeń, uzupełniona akcentami drewna w nowoczesnej stylistyce z pozostawioną przestrzenią na wypełnienie domowym ogniskiem i rodzinną aurą, która nada temu wnętrzu wartość bezcenną, ale to już po stronie Inwestorów.",

  interiorDesignProjectShortDescription26: "DOM JEDNORODZINNY 273M2 | RYDZYNY",
  interiorDesignProjectLongDescription26: "Klimat zimowy, świąteczny w akompaniamencie pokaźnych przeszkleń plus naturalne wnętrze inspirowane kolorami ziemi - połączenie doskonałe! Warto wspomnieć, że nasze ulubione.",

  interiorDesignProjectShortDescription27: "STREFA WSPÓLNA | DZIAŁKOWA 34 | KONSTANTYNÓW ŁÓDZKI",
  interiorDesignProjectLongDescription27: "Kolejny raz musimy się przyznać do odpowiedzialności za codzienną drogę do i z domu mieszkańców nowej inwestycji zespołu budynków wielorodzinnych, tym razem w Konstantynowie Łódzkim. Bierzemy na siebie całą winę, guilty as charged. Mamy jednak szczerą nadzieję, że sprawdzi się ona przynajmniej równie dobrze jak w poprzednich inwestycjach, a co do tego - nie mamy na ten moment żadnych wątpliwości!",

  interiorDesignProjectShortDescription28: "DOM JEDNORODZINNY 286M2 | ŻELECHLINEK",
  interiorDesignProjectLongDescription28: "Wprawnie przenosimy się na wschód województwa łódzkiego, gdzie mamy przyjemność projektować dwuetapową koncepcję architektury wnętrz domu jednorodzinnego. Projekt zakładał całkowity remont części istniejącej oraz rozbudowę budynku o strefę dzieci, która zawiera dwupoziomowe pokoje młodzieżowe oraz przestrzeń łączoną dla najmłodszych.",

  contemporaryArchitectureProjectShortDescription1: "DOM JEDNORODZINNY 130M2 | PIĄTKOWISKO",
  contemporaryArchitectureProjectLongDescription1: "Prawidłowo zaprojektowane wnętrze zaczyna się na etapie projektu domu, który swoimi architektonicznymi właściwościami definiuje je od samego początku inwestycji! Przy każdym projekcie zwracamy uwagę między innymi na kierunki świata, w tym przypadku front działki znajduje się od zachodu, co nie ograniczyło projektu w żadnym stopniu zachowując zarówno światło zachodnie jak i prywatność w strefie dziennej. Dom jednorodzinny szyty na miarę - projekt indywidualny -",

  contemporaryArchitectureProjectShortDescription2: "DOM JEDNORODZINNY 227M2 | ŁÓDŹ",
  contemporaryArchitectureProjectLongDescription2: "Efekt współpracy z MSW Biuro Architektoniczne z ubiegłego roku - - dom jednorodzinny z dachem wielospadowym, którego... nie widać - - tym samym marzenie Inwestora sprostało Warunkom Zabudowy, które jednoznacznie wskazywały zakaz dachu płaskiego - Win-Win",

  contemporaryArchitectureProjectShortDescription3: "DOM JEDNORODZINNY 300M2 | DOBROŃ",
  contemporaryArchitectureProjectLongDescription3: "Najistotniejsze aspekty, którymi kierowaliśmy się podczas opracowania koncepcji? - - Zdecydowanie układ przestrzenno-funkcjonalny, zoptymalizowany dla młodej pary Inwestorów. - - Natura - działka od północy oraz wschodu graniczy z lasem, zadbaliśmy więc o otwarcia strefy dziennej na otaczającą przyrodę. - - Prywatność oraz strefowanie - firmowe biuro z oddzielnym wejściem od strony wjazdu na działkę w przeciwieństwie do przestrzeni osobistej, rodzinnej zlokalizowanej z dala od drogi publicznej, z tarasem oraz ogrodem w otoczeniu zieleni. - - Tudzież jak zawsze i wszędzie - Architektura!",

  atelierDescriptionPartOne: "ARCHITEKTURA ODWIECZNIE STANOWI NIEODRĘBNĄ CZĘŚĆ ŻYCIA CZŁOWIEKA. OTACZA NAS OD PIERWSZYCH CHWIL DNIA DO JEGO ZAKOŃCZENIA, W FORMIE BUDYNKU W KTÓRYM SIĘ ZNAJDUJEMY, MIEJSCA PRACY W KTÓREJ  WYKONUJEMY ETATOWE CZYNNOŚCI, URBANISTYKI OKALAJĄCEJ POZOSTAŁE CODZIENNE AKTYWNOŚCI, KOŃCZĄC NA DOMOWYM OGNISKU, W KTÓRYM KREUJEMY WŁASNĄ ŚWIADOMOŚĆ, CHARAKTER ORAZ AMPLITUDĘ OSOBISTEGO SAMOPOCZUCIA.",
  atelierDescriptionPartTwo: "STUDIO PROJEKTOWE ATELIER WŁODARCZEWSKI ZOSTAŁO UTWORZONE W CELU DĄŻENIA DO STWORZENIA DOSKONAŁEJ PRZESTRZENI FUNKCJONALNEJ DLA KORZYSTAJĄCYCH Z JEGO USŁUG KLIENTÓW. NA PODSTAWIE IDENTYFIKACJI DOCELOWYCH USŁUGOBIORCÓW/UŻYTKOWNIKÓW DANEGO MIEJSCA, WIEDZY NABYTEJ NA KRAJOWYCH ORAZ ZAGRANICZNYCH UCZELNIACH WYŻSZYCH, KLUCZOWEGO DOŚWIADCZENIA PROJEKTOWEGO UZYSKANEGO PODCZAS PRAKTYK, STAŻY W UZNANYCH PRACOWNIACH ORAZ POPRZEDZAJĄCYCH PROJEKT ANALIZACH ARCHITEKTONICZNYCH, TWORZONY JEST PRODUKT FINALNY  DOSTOSOWANY DO POTRZEB ZAMAWIAJĄCEGO POD WZGLĘDEM ERGONOMII, ESTETYKI, FUNKCJONALNOŚCI, OBECNYCH TRENDÓW A TAKŻE ZAŁOŻONEGO PRZEDWSTĘPNEGO KOSZTORYSU.",
  atelierDescriptionPartThree: "ARCHITEKTURA WNĘTRZA PRZEZ WIELU DOTYCHCZAS POSTRZEGANA JAKO DODATEK POCHŁANIAJĄCY FINANSE INWESTORÓW ZMIENIŁA SWÓJ WIZERUNEK NA PRZESTRZENI UBIEGŁEJ DEKADY, UTOŻSAMIAJĄC SIĘ JEDNOCZEŚNIE Z KWESTIĄ KONIECZNĄ PRZED PRZYSTĄPIENIEM DO UŻYTKOWANIA POTENCJALNEGO APARTAMENTU/DOMU/LOKALU.  STUDIO OFERUJE ZARÓWNO KONCEPCYJNE JAK I KOMPLETNE, WYKONAWCZE PROJEKTY WNĘTRZ “USZYTE NA MIARĘ” NAJWYŻSZEJ JAKOŚCI, KŁADĄC NAJWIĘKSZY NACISK NA WYMAGANIA ORAZ OCZEKIWANIA KLIENTA.  WNĘTRZE WEDŁUG ZAŁOŻONEJ FILOZOFII FIRMOWEJ STANOWI FORMĘ ODZWIERCIEDLENIA CHARAKTERU KORZYSTAJĄCYCH Z NIEGO UŻYTKOWNIKÓW, IDENTYFIKUJĄCĄ SIĘ Z CODZIENNYMI PODSTAWOWYMI POTRZEBAMI, CZEGO WYNIKIEM JEST SZEROKI ZAKRES STYLISTYCZNY PROPONOWANYCH PRZEZ STUDIO KONWENCJI:",
  atelierDescriptionPartFour: "MINIMALISTYCZNA | NOWOCZESNA | SKANDYNAWSKA | NOWOCZESNA KLASYCZNA | KLASYCZNA LOFTOWA | INDUSTRIALNA | GLAMOUR | EKOLOGICZNA | RUSTYKALNA | PROWANSALSKA ORAZ WIELE INNYCH NA ŻYCZENIE INWESTORA",

  offer: "Oferta",

  offerSubTitlePartOne: "INWENTARYZACJA ARCHITEKTONICZNA + WIZJA LOKALNA",
  offerDescriptionPartOne: "PRZED PRZYSTĄPIENIEM DO PROJEKTU, W PRZYPADKU W KTÓRYM NIE JESTEŚMY W DYSPOZYCJI RYSUNKÓW TECHNICZNYCH POWYKONAWCZYCH PROJEKTOWANEGO POMIESZCZENIA, NALEŻY WYKONAĆ INWENTARYZACJĘ ARCHITEKTONICZNĄ W CELU UZYSKANIA SPÓJNYCH PODKŁADÓW PROJEKTOWYCH ZAWIERAJĄCYCH NIEZBĘDNE POMIARY DZIĘKI KTÓRYM JEST MOŻLIWE STWORZENIE PROJEKTU WYKONAWCZEGO.",

  offerSubTitlePartTwo: "PROJEKT WNĘTRZA MIESZKALNEGO",
  offerDescriptionPartTwo: "STUDIO OFERUJE PROJEKTY WNĘTRZ LOKALI MIESZKALNYCH ZARÓWNO RYNKU PIERWOTNEGO JAK I WTÓRNEGO, APARTAMENTÓW W KOMPLEKSACH WIELORODZINNYCH, ARANŻACJĘ POMIESZCZEŃ DOMÓW JEDNO- LUB WIELORODZINNYCH.",

  offerSubTitlePartThree: "PROJEKT WNĘTRZA KOMERCYJNEGO",
  offerDescriptionPartThree: "WYKONYWANE SĄ RÓWNIEŻ PROJEKTY WNĘTRZ UŻYTECZNOŚCI PUBLICZNEJ TAKICH JAK POKOJE HOTELOWE, KANCELARIE, RESTAURACJE, BUTIKI, SALONY, GALERIE, ATELIER, STREFY WEJŚCIOWE, POMIESZCZENIA BIUROWE I INNE.",

  offerSubTitlePartFour: "PROJEKT ARCHITEKTONICZNY KONCEPCYJNY",
  offerDescriptionPartFour: "DODATKOWO STUDIO OFERUJE PROJEKTY ARCHITEKTONICZNE KONCEPCYJNE BUDYNKÓW WOLNOSTOJĄCYCH O SKALI NIEPRZEKRACZAJĄCEJ POWIERZCHNI UŻYTKOWEJ 400 M2 LUB NA ŻYCZENIE KLIENTA KOMPLETNE PROJEKTY INDYWIDUALNE DOMÓW JEDNORODZINNYCH WRAZ Z PROCESEM UZYSKANIA DECYZJI O POZWOLENIU NA BUDOWĘ.",

  leadingArchitect: "Architekt prowadzący",
  interiorDesigner: "Projektant wnętrz",
  architect: "Architekt",
  graphicDesigner: "Grafik",

  education: "Wykształcenie",

  educationPartOne: "ARCHITECTURE  -  INTERNATIONAL FACULTY OF ENGINEERING  -  ŁÓDŹ, POLSKA",
  educationPartTwo: "EUROPEAN PROJECT SEMESTER  -  UNIVERSITY OF ANTWERP  -  ANTWERPIA, BELGIA",
  educationPartThree: "ARCHITEKTURA I URBANISTYKA  -  POLITECHNIKA ŁÓDZKA  -  ŁÓDŹ, POLSKA",

  achievements: "Osiągnięcia",
  thirdPlace: "III Miejsce",
  achievementPartOne: "KONKURS URBANISTYCZNY NA KONCEPCJĘ ZAGOSPODAROWANIA TERENU MIEJSKIEGO OŚRODKA SPORTU I REKREACJI W PABIANICACH",

  realizations: "Realizacje", 

  poland: "Polska",
  ireland: "Irlandia",

  lodz: "Łódź",
  warsaw: "Warszawa",
  ksawerow: "Ksawerów",
  lask: "Łask",
  dobron: "Dobroń",
  piatkowisko: "Piątkowisko",
  dublin: "Dublin",
  slawa: "Sława",
  wroclaw: "Wrocław",
  radomsko: "Radomsko",
  slupsk: "Słupsk",
  wiaczynDolny: "Wiączyń Dolny",
  zgierz: "zgierz",

  inCooperationWithMswPiotrMarcinkowski: "WE WSPÓŁPRACY Z MSW BIURO ARCHITEKTONICZNE PIOTR MARCINKOWSKI (WNĘTRZA)",
  inCooperationWithBonarteAgnieszkaPierzchala: "WE WSPÓŁPRACY Z BONARTE AGNIESZKA PIERZCHAŁA (WNĘTRZA)",

  realizationAloneSixteen: "WNĘTRZE APARTAMENTU - CASA MIA",
  realizationAloneFifteen: "WNĘTRZE APARTAMENTU - APARTAMENTY ILUMINO II",
  realizationAloneFourteen: "WNĘTRZE SEGMENTU - KOMFORTOWE OSIEDLE",
  realizationAloneThirteen: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationAloneTwelve: "WNĘTRZE APARTAMENTU - GÓRNA",
  realizationAloneEleven: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationAloneTen: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationAloneNine: "WNĘTRZE APARTAMENTU",
  realizationAloneEight: "KONCEPCJA DOMU JEDNORODZINNEGO",
  realizationAloneSeven: "KONCEPCJA WNĘTRZA APARTAMENTU - WILLE JANA",
  realizationAloneSix: "WNĘTRZE APARTAMENTU - BULWARY AUGUSTÓWKA",
  realizationAloneFive: "WNĘTRZE DOMU JEDNORODZINNEGO - ŁAGIEWNIKI",
  realizationAloneFour: "KONCEPCJA DOMU JEDNORODZINNEGO - JULIANÓW",
  realizationAloneThree: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationAloneTwo: "WNĘTRZE SALONU OPTYCZNEGO - CH MAGNOLIA PARK",
  realizationAloneOne: "WNĘTRZE DOMU JEDNORODZINNEGO",

  realizationInCoopWithMswPiotrMarcinkowskiTen: "KONCEPCJA WNĘTRZA DOMU JEDNORODZINNEGO - DOLINA ŁÓDKI",
  realizationInCoopWithMswPiotrMarcinkowskiNine: "WNĘTRZE STREFY WSPÓLNEJ AQUAPARKU W RADOMSKU",
  realizationInCoopWithMswPiotrMarcinkowskiEight: "WNĘTRZE APARTAMENTU - NOVO PARK",
  realizationInCoopWithMswPiotrMarcinkowskiSeven: "WNĘTRZE STREFY WSPÓLNEJ - OSIEDLE OSKAR",
  realizationInCoopWithMswPiotrMarcinkowskiSix: "WNĘTRZE STREFY WSPÓLNEJ AQUAPARKU “TRZY FALE”  W SŁUPSKU",
  realizationInCoopWithMswPiotrMarcinkowskiFive: "KONCEPCJA WNĘTRZA DOMU JEDNORODZINNEGO - ŁAGIEWNIKI",
  realizationInCoopWithMswPiotrMarcinkowskiFour: "KONCEPCJA WNĘTRZA DOMU JEDNORODZINNEGO - RATAJA PARK",
  realizationInCoopWithMswPiotrMarcinkowskiThree: "WNĘTRZE PRZYCHODNI LEKARSKIEJ - DOLINA ŁÓDKI",
  realizationInCoopWithMswPiotrMarcinkowskiTwo: "WNĘTRZE STREFY WSPÓLNEJ - NOVO PARK",
  realizationInCoopWithMswPiotrMarcinkowskiOne: "KONCEPCJA WNĘTRZA APARTAMENTU - NOVO PARK",

  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyEight: "WNĘTRZE APARTAMENTU - APARTAMENTY ARTE",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentySeven: "WNĘTRZE MIESZKANIA - OLECHÓW",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentySix: "WNĘTRZE DOMU JEDNORODZINNEGO - STARE ZŁOTNO",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyFive: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyFour: "WNĘTRZE DOMU JEDNORODZINNEGO - AUGUSTÓW",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyThree: "WNĘTRZE MIESZKANIA - OLECHÓW",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyTwo: "WNĘTRZE MIESZKANIA - ŚRÓDMIEŚCIE",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwentyOne: "WNĘTRZE APARTAMENTU - ATRIUM RADOGOSZCZ (3)",

  realizationInCoopWithBonarteAgnieszkaPierzchalaTwenty: "WNĘTRZE APARTAMENTU - ATRIUM RADOGOSZCZ (2)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaNineteen: "WNĘTRZE APARTAMENTU - CENTRAL PARK APARTMENTS (2)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaEighteen: "WNĘTRZE MIESZKANIA - OLECHÓW",
  realizationInCoopWithBonarteAgnieszkaPierzchalaSeventeen: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationInCoopWithBonarteAgnieszkaPierzchalaSixteen: "WNĘTRZE APARTAMENTU - ATRIUM RADOGOSZCZ (1)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaFifteen: "WNĘTRZE APARTAMENTU - TOMCIA PALUCHA (2)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaFourteen: "WNĘTRZE APARTAMENTU - TOMCIA PALUCHA (1)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaThirteen: "WNĘTRZE APARTAMENTU - APARTAMENTY NIEMCEWICZA 17",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwelve: "WNĘTRZE APARTAMENTU - CENTRAL PARK APARTMENTS (1)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaEleven: "WNĘTRZE APARTAMENTU - NARUTOWICZA RESIDENCE (4)",

  realizationInCoopWithBonarteAgnieszkaPierzchalaTen: "WNĘTRZE TOALET PUBLICZNYCH",
  realizationInCoopWithBonarteAgnieszkaPierzchalaNine: "WNĘTRZE DOMU JEDNORODZINNEGO - BRZOZOWA ALEJA",
  realizationInCoopWithBonarteAgnieszkaPierzchalaEight: "WNĘTRZE DOMU LETNISKOWEGO",
  realizationInCoopWithBonarteAgnieszkaPierzchalaSeven: "WNĘTRZE APARTAMENTU - NARUTOWICZA RESIDENCE (3)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaSix: "WNĘTRZE APARTAMENTU - NARUTOWICZA RESIDENCE (2)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaFive: "WNĘTRZE APARTAMENTU - NARUTOWICZA RESIDENCE (1)",
  realizationInCoopWithBonarteAgnieszkaPierzchalaFour: "WNĘTRZE DOMU JEDNORODZINNEGO - NOWOSOLNA",
  realizationInCoopWithBonarteAgnieszkaPierzchalaThree: "WNĘTRZE POWIERZCHNI BIUROWYCH - ŚRÓDMIEŚCIE",
  realizationInCoopWithBonarteAgnieszkaPierzchalaTwo: "WNĘTRZE DOMU JEDNORODZINNEGO",
  realizationInCoopWithBonarteAgnieszkaPierzchalaOne: "WNĘTRZE SALONU SZTUKATERII GIPSOWEJ",

  office: "Biuro"
}

export default polishLanguage