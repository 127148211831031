import React from 'react'
import { languageHandler } from '../../../App'
import styles from './SeeMoreButton.module.scss'

type SeeMoreButtonProps = {
  onClick: () => void
  showSeeMore: boolean
}

export default function SeeMoreButton(props: SeeMoreButtonProps) {
  return (
    <button onClick={props.onClick} className={styles.seeMoreButton}>
      {props.showSeeMore ? languageHandler.getString("seeMore") : languageHandler.getString("seeLess")}
    </button>
  )
}
