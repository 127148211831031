import React from 'react'
import { languageHandler } from '../../../App'
import styles from "./Contact.module.scss"

export default function Contact() {
  return (
    <div id="contact" className={styles.contact}>
      <div className={styles.contactTitle}>
        {languageHandler.getString("contact")}
      </div>
      <div className={styles.lineBreak}></div>
      <div className={styles.contactDescription}>
        {languageHandler.getString("office")} | {languageHandler.getString("leadingArchitect")} - ADRIAN WŁODARCZEWSKI
      </div>
      
      <div className={styles.lineBreak}></div>
      <a className={styles.link} href="mailto:aw.adrianwlodarczewski@gmail.com">AW.ADRIANWLODARCZEWSKI@GMAIL.COM</a>
      <div className={styles.lineBreak}></div>
      <a className={styles.link} href="tel:0048501144936">+48 501 144 936</a>
      <div className={styles.lineBreak}></div>
      <span className={styles.address}>UL. WILEŃSKA | 95-200 PABIANICE</span>

    </div>
  )
}
