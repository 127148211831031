import React from 'react'
import Atelier from './atelier/Atelier'
import Contact from './contact/Contact'
import ContemporaryArchitecture from './contemporaryArchitecture/ContemporaryArchitecture'
import styles from "./Home.module.scss"
import InteriorDesign from './interiorDesign/InteriorDesign'
import WhatDoWeDo from './whatDoWeDo/WhatDoWeDo'
import FacebookLogo  from "../../../img/facebookLogo.png"
import InstagramLogo  from "../../../img/instagramLogo.png"

export default function Home() {
  
  return (
    <div>
      <div className={styles.homeLogoContainer}>
        <div className={styles.homeLogo}/>
        <div className={styles.homeImg}/>
      </div>

      <div className={styles.socialNetworks}>
        <a href="https://www.facebook.com/atelierwlodarczewski">
          <img className={styles.facebookLogo} src={FacebookLogo} alt="Facebook logo"/>
        </a> 
        <a href="https://www.instagram.com/atelierwlodarczewski/">
          <img className={styles.instagramLogo} src={InstagramLogo} alt="Instagram logo"/>
        </a> 
      </div>

      <WhatDoWeDo />
      <InteriorDesign />
      <ContemporaryArchitecture />
      <Atelier />
      <Contact />
    </div>
  )
}
