import { getProjectData } from "../../../../helpers/CommonFuntions";
import { CompletedInvestmentData } from "../completedInvestment/CompletedInvestment";

const SHORT_DESCRIPTION = "contemporaryArchitectureProjectShortDescription"
const LONG_DESCRIPTION = "contemporaryArchitectureProjectLongDescription"
const BASE_PATH = "/assets/img/contemporaryArchitecture/project_"

export const contemporaryArchitectureData: CompletedInvestmentData[]  = [
  getProjectData("1", [
    "0", "1", "2"
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),
  getProjectData("2", [
    "0", "1", "2", "3", 
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),
  getProjectData("3", [
    "0", "1", "2", "3", 
  ], "jpg", BASE_PATH, SHORT_DESCRIPTION, LONG_DESCRIPTION),
]