import React, { Component } from 'react'
import styles from './Header.module.scss';
import { ReactComponent as HamburgerMenu } from '../../img/hamburgerMenu.svg'
import TextLogoImg from '../../img/textLogo.png';
import TextLogoSmallImg from '../../img/textLogoSmall.png';
import { HashLink } from 'react-router-hash-link';
import {scrollWithOffset} from '../../helpers/ScrollHelper'
import { languageHandler } from '../App';

type HeaderProps = {}
type HeaderState = {
  isHeaderSmall: boolean
}

export default class Header extends Component<HeaderProps, HeaderState> {

  constructor(props: HeaderProps) {
    super(props)
    this.state = {
      isHeaderSmall: false
    }
  }

  scrollListener = () => {
    if(window.scrollY > 100 && !this.state.isHeaderSmall) {
      this.setState({
        isHeaderSmall: true
      })
    }
    else if(window.scrollY < 100 && this.state.isHeaderSmall) {
      this.setState({
        isHeaderSmall: false
      })
    }
  }
  
  componentDidMount() {
    document.addEventListener('scroll', this.scrollListener);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollListener)
  }

  menuItems = () => 
    <div className="navbar-nav"
      data-toggle="collapse" 
      data-target=".navbar-collapse.show">
      <HashLink 
        to={{ pathname: "/", hash: "interiordesign"}} 
        className={styles.interiorDesign}
        scroll={(el) => scrollWithOffset(el, 0)}>
        {languageHandler.getString("interiorDesign")}
      </HashLink>
      <HashLink 
        to={{ pathname: "/", hash: "contemporaryarchitecture"}} 
        className={styles.contemporaryArchitecture}
        scroll={(el) => scrollWithOffset(el, -100)}>
        {languageHandler.getString("contemporaryArchitecture")}
      </HashLink>
      <HashLink 
        to={{ pathname: "/", hash: "atelier" }} 
        className={styles.atelier} 
        scroll={(el) => scrollWithOffset(el, -100)}>
        {languageHandler.getString("atelier")}
      </HashLink>
      <HashLink 
        to={{ pathname: "/", hash: "contact"}}
        className={styles.contact}
        scroll={(el) => scrollWithOffset(el, -100)}>
        {languageHandler.getString("contact")}
      </HashLink>
    </div>
 
  textLogo = () =>  
    <HashLink 
      to={"/#"}
      scroll={(el) => scrollWithOffset(el, -100)}>
      {this.state.isHeaderSmall
        ? <img className={styles.textLogoImgSmall} src={TextLogoSmallImg} alt={"Company logo"}/>
        : <img className={styles.textLogoImg} src={TextLogoImg} alt={"Company logo"}/>}
    </HashLink>

  render() {
    return (
      <div className={styles.header}>
        <div className={styles.headerContainer}>
          <nav className="navbar navbar-expand-lg">
            {this.textLogo()}
            <button 
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <HamburgerMenu className={styles.headerTooglerIcon}/>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <div className="mr-auto"></div>
              {this.menuItems()}
            </div>
          </nav>
        </div>
      </div>
    );
  }
}
