import React from 'react'
import { languageHandler } from '../../App'
import { CompletedInvestmentData } from '../home/completedInvestment/CompletedInvestment'
import styles from './CompletedInvestmentDetails.module.scss'

export default function CompletedInvestmentDetails(props: any) {
  const data: CompletedInvestmentData = props.location.state

  if(data === undefined)
    return <div>Error 404 page not found</div>
  
  const imageWithDescription = (
    imageBasePath: string,
    imageName: string,
    imageExtension: string,
    shortDescriptionStringId: string,
    longDescriptionStringId: string,
    isUppercase: boolean) => 
    <div className={styles.carouselItemContent}>
      <div className={styles.firstImageDescription}>
        <div className={styles.shortDescription}>{languageHandler.getString(shortDescriptionStringId)}</div>
        <div 
          className={`${styles.longDescription} ${isUppercase && styles.uppercase}`}>
          {languageHandler.getString(longDescriptionStringId)}
        </div>
      </div>

      <img 
        className={`${styles.firstImage} d-block w-100`}
        src={`${process.env.PUBLIC_URL}${imageBasePath}${imageName}.${imageExtension}`}
        alt={`Investments visualisation`}/>
    </div>

  return (
    <div className={styles.completedInvestmentDetails}>
      <div id="carouselExampleIndicators" className={`${styles.carousel} carousel slide`} >
        <div className="carousel-inner">
          <div key={Math.random()} className={`carousel-item active`}>
            {imageWithDescription(
              data.imagesBasePath,
              data.imagesNames[0],
              data.imagesExtension,
              data.shortDescriptionStringId,
              data.longDescriptionStringId,
              false)}
          </div>
          
            {data.imagesNames.map((imageName) => {
              let imageToShow = null

              const imageSplit = imageName.split("_")
              if(imageSplit[1] === "previousState.jpg")
                imageToShow = imageWithDescription(
                  data.imagesBasePath, imageName, data.imagesExtension, data.shortDescriptionStringId, "previousState", true)

              else if(imageSplit[1] === "realization.jpg") 
                imageToShow = imageWithDescription(
                  data.imagesBasePath, imageName,  data.imagesExtension, data.shortDescriptionStringId, "realization", true)
                  
              else
                imageToShow = 
                  <img 
                    className={`d-block w-100`}
                    src={`${process.env.PUBLIC_URL}${data.imagesBasePath}${imageName}.${data.imagesExtension}`}
                    alt={`Investments visualisation`}/>

              return (
                <div key={Math.random()} className={`carousel-item`}>
                  {imageToShow}
                </div>
              )
            })}
        </div>

        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span className={`${styles.carouselControlIcon} carousel-control-next-icon`} aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span className={`${styles.carouselControlIcon} carousel-control-prev-icon`} aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
      </div>
    </div>
  )
}
